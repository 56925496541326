import {
  ECustomerMaskFeatureGeometryType,
  TCustomerCategories,
  TCustomerMaskFeatureGeometry,
  TCustomerMaskFeatureGeometryMultiLinesCoordinates,
  TCustomerMaskFeatureGeometryMultiPointsCoordinates,
  TCustomerMaskFeatureGeometryMultiPolygonCoordinates,
} from '@/helpers/API/requests/interfaces/customer';
import { toRGBA } from '@/helpers/color';

const drawMultiPolygons = (context: CanvasRenderingContext2D, coordinates: TCustomerMaskFeatureGeometryMultiPolygonCoordinates) => {
  coordinates.forEach((coordinate) => {
    context.beginPath();
    coordinate.forEach((points) => {
      points.forEach(([x, y]) => {
        context.lineTo(x, y);
      });
    });
    context.closePath();
    context.fill();
  });
};

const drawMultiPoints = (context: CanvasRenderingContext2D, coordinates: TCustomerMaskFeatureGeometryMultiPointsCoordinates) => {
  coordinates.forEach(([x, y, radius]) => {
    context.beginPath();
    context.arc(x, y, radius, 0, 2 * Math.PI);
    context.closePath();
    context.fill();
  });
};

const drawMultiLineString = (context: CanvasRenderingContext2D, coordinates: TCustomerMaskFeatureGeometryMultiLinesCoordinates) => {
  coordinates.forEach(([[x1, y1], [x2, y2]]) => {
    context.save();
    context.beginPath();
    context.moveTo(x1, y1);
    context.lineTo(x2, y2);
    context.stroke();
    context.closePath();
    context.restore();
  });
};

const drawGeometry = (context: CanvasRenderingContext2D, geometry: TCustomerMaskFeatureGeometry) => {
  switch (geometry.type) {
    case ECustomerMaskFeatureGeometryType.MultiPolygon:
      drawMultiPolygons(context, geometry.coordinates as TCustomerMaskFeatureGeometryMultiPolygonCoordinates);
      break;
    case ECustomerMaskFeatureGeometryType.MultiPoint:
      drawMultiPoints(context, geometry.coordinates as TCustomerMaskFeatureGeometryMultiPointsCoordinates);
      break;
    case ECustomerMaskFeatureGeometryType.MultiLineString:
      drawMultiLineString(context, geometry.coordinates as TCustomerMaskFeatureGeometryMultiLinesCoordinates);
      break;
    default:
      console.warn(`Type ${geometry.type} not supported !`);
      break;
  }
};

const drawPolygon = (context: CanvasRenderingContext2D, polygons: TCustomerCategories) => {
  polygons.forEach((polygon) =>
    polygon.masks.forEach((mask) => {
      mask.features.forEach(({ properties, geometry }) => {
        const color = toRGBA('#0000FF', properties?.intensity ?? 1);
        context.fillStyle = color;
        context.strokeStyle = color;
        drawGeometry(context, geometry);
      });
    })
  );
};

export default drawPolygon;

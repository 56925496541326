import React from 'react';

import Button from '@/components/Button';
import LocaleText from '@/components/Intl/LocaleText';
import PopinDiagnosis from '@/components/PopinDiagnosis';
import ScoreCard from '@/components/ScoreCard';
import Spinner from '@/components/Spinner';
import Title from '@/components/Title';

import { TCustomerSelfieResponse } from '@/helpers/API/requests/interfaces/customer';

import { ProcessContext } from '..';

import { TDiagnosisStep } from './interfaces';
import Styles from './styles.module.scss';

const DiagnosisStep: React.FC<TDiagnosisStep> = ({ onGoToDetailedScore, onSubmit, setScoreIndexToDisplay }) => {
  const { picture, diagnosis } = React.useContext(ProcessContext);
  const { results } = diagnosis as TCustomerSelfieResponse;

  return results.length > 0 ? (
    <div className={Styles.diagnosisStep}>
      <div className={Styles.diagnosisStep__main} style={{ backgroundImage: `url(${picture})` }}>
        <div className={Styles.diagnosisStep__colorLayer}>
          <div className={Styles.diagnosisStep__scoreContainer}>
            <Title className={Styles.diagnosisStep__title} type='h2'>
              <LocaleText>page.diagnosisStep.title</LocaleText>
            </Title>
            {results.map((card, index) => {
              if (card.name !== '')
                return (
                  <ScoreCard
                    key={card.name}
                    data={card}
                    index={index}
                    onGoToDetailedScore={onGoToDetailedScore}
                    setScoreIndexToDisplay={setScoreIndexToDisplay}
                  />
                );
              else return null;
            })}
            <div className={Styles.diagnosisStep__details}>
              <LocaleText>page.diagnosisStep.details</LocaleText>
            </div>
          </div>
        </div>
      </div>

      <div className={Styles.diagnosisStep__footer}>
        <Button rounded onClick={onSubmit} className={Styles.diagnosisStep__button}>
          <LocaleText>page.diagnosisStep.submit</LocaleText>
        </Button>
      </div>
    </div>
  ) : (
    <div className={Styles.diagnosisStep}>
      <Spinner className={Styles.diagnosisStep__loading} />
      <PopinDiagnosis active={true} />
    </div>
  );
};

export default DiagnosisStep;

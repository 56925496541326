import { FunctionComponent, useContext, useEffect, useState } from 'react';

import HomeImage from '@/globals/images/hero_homeStep.jpg';

import Button from '@/components/Button';
import Inner from '@/components/Inner';
import LocaleText from '@/components/Intl/LocaleText';
import Title from '@/components/Title';

import { shouldRefresh } from '@/helpers/refresh';

import Styles from './styles.module.scss';
import { THomeStep } from './interfaces';
import Form from '@/components/Form';
import { ECommerceContext } from '@/providers/AuthProvider/eCommerceInfoProvider';

const HomeStep: FunctionComponent<THomeStep> = ({ onSubmit }) => {
  const { title, subtitle, description, cguLink, eCommerceLoading } = useContext(ECommerceContext);

  const [consentChecked, setConsentChecked] = useState(false);

  useEffect(() => {
    if (shouldRefresh()) {
      window.location.reload();
    }
  }, []);

  if (eCommerceLoading) {
    return <div>Chargement...</div>;
  } else {
    return (
      <div className={Styles.homeStep}>
        <div>
          <div className={Styles.homeStep__visual}>
            <img className={Styles['homeStep__visual-image']} src={HomeImage} alt='Home' />
          </div>
          <Inner className={Styles.homeStep__content}>
            <Title type='h2' className={Styles.homeStep__title}>
              {title || <LocaleText>page.homeStep.title</LocaleText>}
            </Title>
            <div className={Styles.homeStep__subtitle}>{subtitle || <LocaleText>page.homeStep.subtitle</LocaleText>}</div>

            <div className={Styles.homeStep__description}>{description || <LocaleText>page.homeStep.description </LocaleText>}</div>

            <div className={Styles.homeStep__consent}>
              <Form.Checkbox
                value={consentChecked}
                name='user_consent'
                required
                onChange={(value) => {
                  setConsentChecked(value ? true : false);
                }}
              />
              <div>
                <LocaleText>page.homeStep.consentSentence</LocaleText>
                <a className={Styles.homeStep__consent__link} href={cguLink} target='_blank' rel='noreferrer'>
                  <LocaleText>page.homeStep.consentLinkText</LocaleText>
                </a>
              </div>
            </div>
          </Inner>
        </div>
        <Button onClick={onSubmit} className={Styles.homeStep__button} rounded disabled={!consentChecked}>
          <LocaleText>page.homeStep.submit</LocaleText>
        </Button>
      </div>
    );
  }
};

export default HomeStep;

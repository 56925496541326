import React from 'react';

import { TTitleProps } from './interfaces';
import Styles from './styles.module.scss';

const Title: React.FC<TTitleProps> = ({ className = '', type, children }) => {
  switch (type) {
    case 'h1':
      return <h1 className={`${Styles['title']} ${className ? className : ''}`}>{children}</h1>;
    case 'h2':
      return <h2 className={`${Styles['title']} ${className ? className : ''}`}>{children}</h2>;
    case 'h3':
      return <h3 className={`${Styles['title']} ${className ? className : ''}`}>{children}</h3>;
    case 'h4':
      return <h4 className={`${Styles['title']} ${className ? className : ''}`}>{children}</h4>;
    default:
      return <p className={`${Styles['title']} ${className ? className : ''}`}>{children}</p>;
  }
};

export default Title;

import { FunctionComponent, useCallback, useContext, useEffect, useRef } from 'react';

import { ProcessContext } from '../../screens/Auth/ProcessAI';

import { provideBase64Image } from '@/helpers/liqa';
import { LocaleContext } from '@/providers/LocaleProvider';
import { ELocale } from '@/providers/LocaleProvider/interfaces';

import { TPictureMobile } from './interfaces';
import Styles from './styles.module.scss';

const PictureMobile: FunctionComponent<TPictureMobile> = ({ onSubmit }) => {
  const { sendPicture } = useContext(ProcessContext);
  const { locale } = useContext(LocaleContext);

  // TODO Implement type based on LIQA doc
  const liqaRef = useRef<any>();

  // TODO Implement type based on LIQA doc
  const handleImageCapture = useCallback(
    async (event: any) => {
      const imageString = await provideBase64Image(event);
      sendPicture(imageString);
      onSubmit(imageString);
    },
    [onSubmit, sendPicture]
  );

  useEffect(() => {
    if (liqaRef.current) {
      const reference = liqaRef.current;
      reference.addEventListener('capture', handleImageCapture);

      return () => reference.removeEventListener('capture', handleImageCapture);
    }
  }, [handleImageCapture]);

  return (
    <div className={Styles.pictureMobile}>
      {/* @ts-ignore TODO: See if it is possible to import the component differently so that it is recognized by TS*/}
      <hautai-liqa
        ref={liqaRef}
        license={process.env.REACT_APP_LIQA_LICENSE_KEY}
        // TODO Extract french texts to a JSON file
        messages={
          locale === ELocale.frFr
            ? `{
                  "source_selection": {
                    "title": "Choisissez l&apos;option de prise de photo",
                    "camera_permissions_warning": {
                      "title": "Accès à la caméra refusé",
                      "instructions_reset": "Modifiez l&apos;autorisation d&apos;accès à la caméra du navigateur pour ce site ou chargez une photo depuis votre appareil",
                      "instructions_reload": "Modifiez l&apos;autorisation d&apos;accès à la caméra du navigateur pour ce site ou chargez une photo depuis votre appareil"
                    },
                    "camera_video": "Prendre une photo",
                    "upload_photo": "Charger depuis l&apos;appareil"
                  },
                  "face_live_video": {
                    "face_displaced": "Placez votre visage dans le cadre",
                    "face_placement_fixed": "Parfait !",
                    "face_distance_far": "Approchez-vous",
                    "face_distance_close": "Éloignez-vous",
                    "face_distance_fixed": "Parfait !",
                    "face_angle_deviation": "Tournez votre visage vers la caméra",
                    "face_angle_fixed": "Parfait !",
                    "lighting_insufficient": "Trouvez un endroit plus lumineux",
                    "lighting_excessive": "Trouvez un endroit moins lumineux",
                    "lighting_angle_deviation": "Tournez-vous face à la lumière",
                    "lighting_fixed": "Parfait !",
                    "capturing": "Regardez la caméra"
                  },
                  "preview": {
                    "title": "Bien joué !",
                    "text": "Voici votre photo",
                    "retake": "Reprendre",
                    "submit": "Envoyer"
                  }
                }`
            : '{}'
        }
      />
    </div>
  );
};

export default PictureMobile;

import React from 'react';

import { TFormMultiStep, TFormMultiStepProps, TFormMultiStepRef } from './interfaces';

import Form from '../Form';
import { TForm, TFormRef } from '../Form/interfaces';
import Icon from '../Icon';
import Inner from '../Inner';
import LocaleText from '../Intl/LocaleText';
import MultiStep, { TMultiStepRef } from '../MultiStep';
import { TMultiStepProps } from '../MultiStep/interfaces';

import Styles from './styles.module.scss';

const FormMultiStep = React.forwardRef<TFormMultiStepRef, TFormMultiStepProps>(
  ({ children, id, initialValues, onSubmit, initialStep, totalSteps = 1, delay, onTransitionStart, onTransitionEnded }, ref) => {
    const [currentStep, setCurrentStep] = React.useState(1);
    const formRef = React.useRef<TFormRef>(null);
    const form = React.useRef<TForm>({});
    const multiStep = React.useRef<TMultiStepRef>(null);
    const isPrev = React.useRef<boolean>(false);
    const [stepTransition, setStepTransition] = React.useState<boolean>(false);
    const [isTransitionPrev, setIsTransitionPrev] = React.useState<boolean>(false);

    const prev = React.useCallback(() => multiStep.current!.prev(), []);
    const onStepChange = React.useCallback<NonNullable<TMultiStepProps['onStepChange']>>((newStep) => {
      setCurrentStep(newStep?.index ?? 1);
    }, []);

    React.useImperativeHandle(
      ref,
      () =>
        ({
          next: () => formRef.current!.next(),
          getCurrentStep: () => multiStep.current!.currentStep,
          prev,
        } as TFormMultiStepRef)
    );

    return (
      <Form
        className={Styles.multiStepForm}
        ref={formRef}
        id={id}
        initialValues={initialValues}
        onSubmit={(_form) => {
          form.current = {
            ...form.current,
            ..._form,
          };
          multiStep.current!.next();
        }}
      >
        <Inner className={Styles.multiStepForm__inner}>
          <div className={Styles.multiStepForm__back}>
            {currentStep > 1 ? (
              <div className={Styles['multiStepForm__back-link']} onClick={() => multiStep.current!.prev()}>
                <Icon icon='arrow-left' className={Styles['multiStepForm__back-icon']} />
                <LocaleText>form.layout.back</LocaleText>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className={`${Styles.multiStepForm__body} ${stepTransition ? Styles['is-transition'] : ''} ${isTransitionPrev ? Styles['is-prev'] : ''}`}>
            <MultiStep
              ref={multiStep}
              initialStep={initialStep}
              delay={400}
              onStepChange={onStepChange}
              onPrevStep={() => {
                isPrev.current = true;
                setIsTransitionPrev(true);
              }}
              onTransitionStart={(from, to) => {
                setTimeout(() => {
                  setStepTransition(true);
                }, 100);
                if (isPrev.current) {
                  isPrev.current = false;
                }
                onTransitionStart?.(from, to);
              }}
              onTransitionEnded={(from, to) => {
                setStepTransition(false);
                setIsTransitionPrev(false);
                onTransitionEnded?.(from, to);
              }}
              onSubmitLastStep={() => {
                onSubmit?.(form.current);
              }}
            >
              {children}
            </MultiStep>
          </div>
          {currentStep !== totalSteps && (
            <div className={Styles.multiStepForm__footer}>
              <div className={Styles.multiStepForm__count}>
                {currentStep}
                {'\u00A0'}
                <LocaleText>form.layout.of</LocaleText>
                {'\u00A0'}
                {totalSteps}
              </div>
              {currentStep === 1 && (
                <p className={Styles.multiStepForm__footer__text}>Vos réponses nous aident uniquement à sélectionner les meilleurs produits</p>
              )}
            </div>
          )}
        </Inner>
      </Form>
    );
  }
);

(FormMultiStep as TFormMultiStep).Input = Form.Input;
(FormMultiStep as TFormMultiStep).Step = MultiStep.Step;

export default FormMultiStep as TFormMultiStep;

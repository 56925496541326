import { TAddProductToShopifyCart, TRemoveProductFromShopifyCart } from './interface';

export const addProductToShopifyCart: TAddProductToShopifyCart = async (cartUrl, id, quantity = 1) => {
  const formData = {
    items: [
      {
        id,
        quantity,
      },
    ],
  };

  try {
    const response = await fetch(`${cartUrl}/add.js`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      return true;
    } else {
      throw new Error("Erreur lors de l'ajout au panier");
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const removeProductFromShopifyCart: TRemoveProductFromShopifyCart = async (cartUrl, id) => {
  try {
    const response = await fetch(`${cartUrl}/change.js`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, quantity: 0 }),
    });

    if (response.ok) {
      return true;
    } else {
      throw new Error('Erreur lors de la suppression du panier');
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ERoutes from './routes';

/**
 * Screens
 */
import ProcessAI from '@/screens/Auth/ProcessAI';
import NotFound from '@/screens/NotFound';

const AuthRouter: React.FC = () => (
  <Routes>
    <Route path={ERoutes.ProcessAI} element={<ProcessAI />} />
    <Route path='*' element={<NotFound redirect={ERoutes.ProcessAI} />} />
  </Routes>
);

export default AuthRouter;

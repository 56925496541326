import { TCustomerAnswerResponse, TECommerceInfo } from './API/requests/interfaces/customer';

type TGenerateCartUrlProps = Pick<TECommerceInfo, 'externalCartUrl' | 'type'> &
  Pick<TCustomerAnswerResponse, 'cart_id'> & {
    externalProductIds: string[];
    customerEmail: string;
  };

type TGenerateCartUrl = (props: TGenerateCartUrlProps) => string;

const generateCartUrl: TGenerateCartUrl = ({ externalCartUrl, type, externalProductIds, customerEmail, cart_id }) => {
  const productIdsString = externalProductIds.join(',');

  let cartQueryString = '';
  switch (type) {
    case 'Custom':
      cartQueryString = `?products=${productIdsString}&cartId=${cart_id}`;
      break;

    case 'Shopify':
      cartQueryString = `?cartId=${cart_id}`;
      break;

    default:
      break;
  }

  const fullCartUrl = customerEmail ? `${externalCartUrl}${cartQueryString}&email=${customerEmail}` : `${externalCartUrl}${cartQueryString}`;
  return fullCartUrl;
};

export default generateCartUrl;

import React from 'react';

import { TButtonProps } from './interfaces';
import Styles from './styles.module.scss';

const Button: React.FC<TButtonProps> = ({
  id = undefined,
  className = '',
  secondary = false,
  outline = false,
  rounded = false,
  disabled = false,
  white = false,
  fullWidth = false,
  backgroundColor,
  size = 'large',
  type = 'button',
  onClick,
  children,
}) => (
  <button
    disabled={disabled}
    id={id}
    type={type}
    className={`${Styles['button']} ${secondary ? Styles['button--secondary'] : ''} ${outline ? Styles['button--outline'] : ''} ${
      rounded ? Styles['button--rounded'] : ''
    } ${white ? Styles['button--white'] : ''} ${fullWidth ? Styles['button--fullWidth'] : ''} ${Styles[`button--${size}`]} ${className ? className : ''}`}
    style={{ backgroundColor }}
    onClick={onClick}
  >
    {children}
  </button>
);

export default Button;

import React from 'react';

import useDidUpdate from '@/hooks/useDidUpdate';

import Step from './Step';

import { TMultiStep, TMultiStepProps, TMultiStepRef } from './interfaces';

export type { TMultiStepRef };

const MultiStep = React.forwardRef<TMultiStepRef, TMultiStepProps>(
  ({ children, initialStep, delay = 0, onStepChange, onPrevStep, onNextStep, onSubmitLastStep, onTransitionStart, onTransitionEnded }, ref) => {
    const id = React.useId();
    const steps = React.useMemo(() => {
      const _steps: React.ReactElement[] = [];

      React.Children.forEach((children as any).type === React.Fragment ? (children as any).props.children : children, (child) => {
        if (typeof child === 'object' && child) {
          switch ((child as any).type) {
            case Step:
              _steps.push(child as React.ReactElement);
              break;
          }
        }
      });
      return _steps;
    }, [children]);

    const getStep = React.useCallback(
      (_step: string | number) => {
        let __step: number;

        if (typeof _step === 'number') {
          __step = _step - 1;
        } else {
          const currentStep = steps.findIndex((step) => step.props.name === _step);

          __step = currentStep !== -1 ? currentStep : 0;
        }
        return Math.max(0, Math.min(__step, steps.length - 1));
      },
      [steps]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const _initialStep = React.useMemo(() => getStep(initialStep ?? 0), []);

    const [step, setStep] = React.useState(_initialStep);
    const [oldStep, setOldStep] = React.useState(-1);

    const _setStep = (newStep: number) => {
      if (step !== newStep) {
        onStepChange?.(
          {
            index: newStep + 1,
            name: steps[newStep].props.name,
          },
          {
            index: step + 1,
            name: steps[step].props.name,
          }
        );
        setOldStep(delay > 0 ? step : -1);
        setStep(newStep);
      }
    };

    React.useImperativeHandle(
      ref,
      () =>
        ({
          currentStep: {
            index: step + 1,
            name: steps[step]?.props.name,
          },
          goToStep: (_step) => _setStep(getStep(_step)),
          next: () => {
            const newStep = steps[step].props.nextStep ? getStep(steps[step].props.nextStep) : step + 1;

            if (newStep >= steps.length) {
              onSubmitLastStep?.();
            } else {
              onNextStep?.(
                {
                  index: newStep + 1,
                  name: steps[newStep].props.name,
                },
                {
                  index: step + 1,
                  name: steps[step].props.name,
                }
              );
              _setStep(newStep);
            }
          },
          prev: () => {
            const stepAliases = [step + 1];

            if (steps[step]?.props.name) {
              stepAliases.push(steps[step].props.name);
            }

            let previousStep = steps.findIndex((step) => stepAliases.includes(step.props.nextStep));

            if (previousStep === -1) {
              previousStep = Math.max(0, step - 1);
            }

            if (previousStep !== step) {
              onPrevStep?.(
                {
                  index: previousStep + 1,
                  name: steps[previousStep].props.name,
                },
                {
                  index: step + 1,
                  name: steps[step].props.name,
                }
              );

              _setStep(previousStep);
            }
          },
        } as TMultiStepRef)
    );

    useDidUpdate(() => {
      const _to = {
        index: step + 1,
        name: steps[step].props.name,
      };
      const _from =
        oldStep >= 0
          ? {
              index: oldStep + 1,
              name: steps[oldStep].props.name,
            }
          : null;

      let timeId =
        delay > 0
          ? setTimeout(() => {
              onTransitionEnded?.(_from!, _to);
              setOldStep(-1);
              timeId = null;
            }, delay)
          : null;

      onTransitionStart?.(_from, _to);

      return () => {
        if (timeId !== null) {
          clearTimeout(timeId);
        }
      };
    }, [step]);

    return !steps || step < 0 ? null : (
      <>
        {oldStep >= 0 ? <React.Fragment key={`${id}-${oldStep}`}>{steps[oldStep]}</React.Fragment> : null}
        <React.Fragment key={`${id}-${step}`}>{steps[step]}</React.Fragment>
      </>
    );
  }
);

(MultiStep as TMultiStep).Step = Step;

export default MultiStep as TMultiStep;

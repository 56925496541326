import React from 'react';

import { TAuthRefreshTokenOptions } from '../../helpers/API/interfaces';

export enum EUserRole {
  /**
   * User has user rights
   */
  USER = 'ROLE_USER',

  /**
   * User is an administrator.
   */
  ADMIN = 'ROLE_ADMIN',
}

export type TUser = {
  /**
   * User name (mainly his email)
   */
  username: string;

  /**
   * Rights given to the user.
   */
  roles: EUserRole[];
};

export interface IAuthState {
  /**
   * User object
   */
  user: TUser | null;
}

export enum EAuthActionType {
  /**
   * Set User on provider
   */
  Set = 'AUTH_SET_USER',

  /**
   * Update User on provider
   */
  Edit = 'AUTH_EDIT_USER',
}

export type TAuthAction = {
  /**
   * type of reducer's action
   */
  type: EAuthActionType;

  /**
   * reducer's action parameter needed to set or update user
   */
  user: TUser | null;
};

export type TAuthReducer = (prevState: IAuthState, action: TAuthAction) => IAuthState;

export enum EAuthType {
  /**
   * Setup basic authentification
   */
  Basic = 'Basic',

  /**
   * Setup bearer token authentification
   */
  BToken = 'Bearer',
}

export type TAuthTypeBasicValues = {
  /**
   * Basic authentification username
   */
  username: string;

  /**
   * Basic authentification password
   */
  password: string;
};

export type TAuthLoginOptions = {
  /**
   * Type of login authentification. Default is Bearer token.
   */
  type?: EAuthType;

  /**
   * Refresh token to store on cookie.
   */
  refreshToken?: string;
};

export type TAuthTypeBTokenValues = string;

export type TAuthTypeValues = TAuthTypeBasicValues | TAuthTypeBTokenValues;

export type TLoginCallback = (values: TAuthTypeValues, user: TUser, options?: TAuthLoginOptions) => void;

export type TLogoutCallback = () => void;

export type TEditUserCallback = (user: TUser) => void;

export interface IAuthProps extends IAuthState {
  /**
   * Login callback used to open new connection
   */
  login: TLoginCallback;

  /**
   * Logout callback used to close current connection
   */
  logout: TLogoutCallback;

  /**
   * Callback used to update current user data.
   */
  editUser: TEditUserCallback;
}

export type TAuthRetrieveUserCallback = (token: string) => Promise<TUser>;

export type TAuthProps = {
  /**
   * Callback used to retrieve current user from token stored on cookie.
   */
  onRetrieveUserFromCookie: TAuthRetrieveUserCallback;

  /**
   * Name of expiration field used by the JWT token, default 'exp'
   */
  tokenExpirationField?: string;

  /**
   * Shift between the way Timestamps are interpreted.
   * PHP use seconds as based while javascript is on milliseconds.
   * default (1000).
   */
  tokenTimestampShift?: number;

  /**
   * Component render during loading.
   */
  renderLoading?: React.ReactElement<any, any> | null;

  /**
   * Options used to configure refresh token.
   */
  refreshTokenOptions?: TAuthRefreshTokenOptions;

  /**
   * React's children
   */
  children: React.ReactNode | React.ReactNode[];
};

import { FunctionComponent, useContext, useEffect, useState } from 'react';

import LocaleText from '@/components/Intl/LocaleText';

import { ReactComponent as Cart } from '@/globals/images/cart.svg';
import { ReactComponent as Checkmark } from '@/globals/images/checkmark.svg';

import Button from '../Button';

import Styles from './styles.module.scss';
import { TRecommendationCard } from './interfaces';
import handleExternalCart from '@/helpers/integrations/manageExternalCart';
import { ECommerceContext } from '@/providers/AuthProvider/eCommerceInfoProvider';

const RecommendationCard: FunctionComponent<TRecommendationCard> = ({
  id,
  externalId,
  name,
  description,
  brand,
  className,
  isVisible = true,
  image_url,
  isActive = false,
  isActivable = true,
  onSelect = () => {},
}) => {
  const { externalCartUrl, type } = useContext(ECommerceContext);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [descriptionIsExpanded, setDescriptionIsExpanded] = useState(false);

  const toggleDescription = () => setDescriptionIsExpanded((current) => !current);

  useEffect(() => {
    if (isInitialRender) setIsInitialRender(false);
    else handleExternalCart(externalCartUrl, externalId, isActive, type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalCartUrl, externalId, isActive, type]);

  return (
    <div className={`${Styles.recommendationCardContainer} ${className ? ` ${className}` : ''}`} data-id={id}>
      <div
        className={`${Styles.recommendationCard} ${isActive ? ` ${Styles['recommendationCard--active']}` : ''}${
          isVisible ? ` ${Styles['recommendationCard--visible']}` : ''
        }`}
      >
        <div className={Styles.recommendationCard__body}>
          <div className={Styles.recommendationCard__visual}>
            <img src={image_url} className={Styles['recommendationCard__visual-image']} alt='card_visual' />
          </div>
          <div className={Styles.recommendationCard__content}>
            <p className={Styles.recommendationCard__title}>{name}</p>
            <p className={Styles.recommendationCard__brand}>{brand?.name}</p>
            <p
              dangerouslySetInnerHTML={{ __html: description }}
              className={`${Styles.recommendationCard__description} ${descriptionIsExpanded ? Styles.recommendationCard__descriptionExpanded : ''}`}
              onClick={toggleDescription}
            ></p>
          </div>
          {isActivable ? (
            <Button
              secondary={!isActive}
              rounded
              fullWidth
              className={Styles.recommendationCard__button}
              onClick={() => {
                onSelect(id, externalId, !isActive, undefined);
              }}
            >
              {isActive ? (
                <>
                  <Checkmark className={Styles.recommendationCard__button__checkIcon} />
                  <div className={Styles['recommendationCard__footer-active']}>
                    <LocaleText>page.recommendationStep.selectButton.selected </LocaleText>
                  </div>
                </>
              ) : (
                <>
                  <Cart className={Styles.recommendationCard__button__cartIcon} />
                  <div className={Styles['recommendationCard__footer-default']}>
                    <LocaleText>page.recommendationStep.selectButton.notSelected </LocaleText>
                  </div>
                </>
              )}
            </Button>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};

export default RecommendationCard;

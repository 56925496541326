import React from 'react';

import Form from '@/components/Form';
import LocaleText from '@/components/Intl/LocaleText';

import BannerImage from '@/globals/images/hero_ageStep.png';

import { ECustomerAge } from '@/helpers/API/requests/interfaces/customer';

import { TPersonalizationStepForm } from './interfaces';
import Layout from './Layout';

const Age: React.FC<TPersonalizationStepForm> = ({ formRef }) => (
  <Layout title='form.personalizationStep.age.title' image={BannerImage}>
    {Object.values(ECustomerAge).map((value: string) => (
      <Form.RadioButton
        key={value}
        name='age'
        value={value}
        onChanged={() => {
          formRef.current!.next();
        }}
      >
        <LocaleText>{'form.personalizationStep.radio.' + value}</LocaleText>
      </Form.RadioButton>
    ))}
  </Layout>
);

export default Age;

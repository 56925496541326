import { FunctionComponent, useContext } from 'react';

import Slider, { Settings } from 'react-slick';

import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Inner from '@/components/Inner';
import LocaleText from '@/components/Intl/LocaleText';
import RecommendationCard from '@/components/RecommandationCard';
import Title from '@/components/Title';
import Spinner from '@/components/Spinner';

import cartImage from '@/globals/images/hero_productChoiceStep.jpg';

import { TCustomerAnswerResponse, TCustomerProposedProduct, TCustomerRoutine } from '@/helpers/API/requests/interfaces/customer';

import { ProcessContext } from '..';

import Styles from './styles.module.scss';
import { TCartStep } from './interfaces';

const CartStep: FunctionComponent<TCartStep> = ({ prev, products, onSubmit }) => {
  const { recommendations } = useContext(ProcessContext);
  const { routines } = recommendations as TCustomerAnswerResponse;

  if (products) {
    let routinesFiltered = routines.filter((routine) => {
      return (
        routine.proposed_products.find(({ externalId }) => products.map((product) => product.externalId).includes(externalId)) !== undefined ||
        routine.sub_routines.filter((sub: TCustomerRoutine) => {
          return sub.proposed_products.find(({ externalId }) => products.map((product) => product.externalId).includes(externalId)) !== undefined;
        }).length > 0
      );
    });

    const sliderSettings: Settings = {
      arrows: false,
      infinite: false,
      variableWidth: true,
    };

    return (
      <div className={Styles.cartStep}>
        <div className={Styles.cartStep__back}>
          <div className={Styles.cartStep__back__link} onClick={prev}>
            <Icon icon='arrow-left' className={Styles.cartStep__back__icon} />
            <LocaleText>form.layout.back</LocaleText>
          </div>
        </div>
        <div className={Styles.cartStep__visual}>
          <img className={Styles.cartStep__visual__Image} src={cartImage} alt='Cart' />
        </div>
        <Inner type='md' className={Styles.cartStep__heading}>
          <Title className={Styles.cartStep__title} type='h2'>
            <LocaleText>page.cartStep.title</LocaleText>
          </Title>
          <Title className={Styles.cartStep__subtitle} type='h3'>
            <LocaleText>page.cartStep.subtitle</LocaleText>
          </Title>
        </Inner>

        <div className={Styles.cartStep__body}>
          <Slider className={Styles.cartStep__slider} {...sliderSettings}>
            {routinesFiltered
              .sort((a: TCustomerRoutine, b: TCustomerRoutine) => a.position - b.position)
              .map((routine: TCustomerRoutine, rIndex: number) => {
                return (
                  <>
                    {routine.proposed_products.find(({ externalId }) => products.map((product) => product.externalId).includes(externalId)) !== undefined ? (
                      <RecommendationCard
                        key={'recommendationCard-' + (routine.position ?? rIndex)}
                        className={Styles.cartStep__routine__card}
                        {...(routine.proposed_products.find(({ externalId }) =>
                          products.map((product) => product.externalId).includes(externalId)
                        ) as TCustomerProposedProduct)}
                        isActivable={false}
                      />
                    ) : (
                      ''
                    )}
                    {routine.sub_routines.map((sub: TCustomerRoutine, sIndex: number) => (
                      <>
                        {sub.proposed_products.find(({ externalId }) => products.map((product) => product.externalId).includes(externalId)) !== undefined ? (
                          <RecommendationCard
                            key={'recommendationCard-' + (routine.position ?? rIndex) + '-' + (sub.position ?? sIndex)}
                            className={Styles.cartStep__routine__card}
                            {...(sub.proposed_products.find(({ externalId }: { externalId: string }) =>
                              products.map((product) => product.externalId).includes(externalId)
                            ) as TCustomerProposedProduct)}
                            isActivable={false}
                          />
                        ) : (
                          ''
                        )}
                      </>
                    ))}
                  </>
                );
              })}
          </Slider>
        </div>

        <Button rounded className={Styles.cartStep__button} onClick={onSubmit}>
          <LocaleText>page.cartStep.submit</LocaleText>
        </Button>
      </div>
    );
  }

  return (
    <div className={Styles.cartStep}>
      <Spinner className={Styles.cartStep__loading} />
    </div>
  );
};

export default CartStep;

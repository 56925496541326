enum ERoutes {
  /**
   * Default home page route.
   */
  Login = '/',

  /**
   * Process IA form steps page.
   */
  ProcessAI = '/',
}

export default ERoutes;

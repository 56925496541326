import { useEffect, useState } from 'react';

import { generateECommerce } from '@/helpers/API/requests/customer';
import { TGenerateECommerceResponse } from '@/helpers/API/requests/interfaces/customer';

export const useGenerateECommerce = () => {
  const scriptElements = document.querySelectorAll('script');
  let eCommerceToken = '';
  scriptElements.forEach((script) => {
    if (script.src.includes('sa_widget.js')) {
      eCommerceToken = script.getAttribute('data-sa-ecom-token') ?? '';
    }
  });
  if (eCommerceToken === '') {
    if (window.location.hostname === 'localhost' || window.location.hostname === 'pharmaciegresillons_horatio-ecom.react.sooyoos.dev') {
      eCommerceToken = '20240221103839696660JSG0AW6NGDNPJJG';
    } else if (
      window.location.hostname === 'ecom-preprod.skinanalysia.fr' ||
      window.location.hostname === 'demo-preprod.skinanalysia.com' ||
      window.location.hostname === 'demo.skinanalysia.com'
    ) {
      eCommerceToken = '202404041120023450422AZWDTKCQTJ20GG';
    } else if (window.location.hostname === 'ecom.skinanalysia.fr') {
      eCommerceToken = '20240404080621207855VF2PHZZ34X1DDH2';
    }
  }

  const [eCommerceData, setECommerceData] = useState<TGenerateECommerceResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [eCommerceError, setECommerceError] = useState<string | null>(null);
  const [token, setToken] = useState('');

  useEffect(() => {
    if (eCommerceToken === '') {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    generateECommerce(eCommerceToken)
      .then((data) => {
        setECommerceData(data);

        const { token } = data;

        setIsLoading(false);
        setToken(token);
      })
      .catch((data) => {
        setIsLoading(false);
        if (data.response.data.error) {
          setECommerceError(data.response.data.error);
        }
      });
  }, [eCommerceToken]);

  return { eCommerceData, isLoading, token, eCommerceError };
};

import axios from 'axios';

import { history } from '../../routers/AppRouter';
import ERoutes from '../../routers/NotAuthRouter/routes';

import Cookie from '../Cookie';
import { getExpirationDate } from '../token';

import IAPI, { TAuthBasicCallback, TAuthTokenCallback } from './interfaces';

const tokenCookieName = '__token';
const refreshTokenCookieName = '__refreshToken';

const API: IAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 120000,
  headers: {
    'Content-Type': 'application/json',
  },
});

let g_interceptorsRequest = -1;

API.setLocale = (locale, { localeVariableName, onTransformLocaleName = (locale) => locale }) => {
  if (g_interceptorsRequest !== -1) {
    API.interceptors.request.eject(g_interceptorsRequest);
  }
  g_interceptorsRequest = API.interceptors.request.use((config) => {
    config.params = {
      ...config.params,
      [localeVariableName]: onTransformLocaleName(locale),
    };
    return config;
  });
};

API.initRefreshToken = ({ refreshTokenUri, onRefreshToken, refreshTokenField = 'refresh_token', tokenExpirationField, tokenHTTPCodeError = 401 }) => {
  API.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      const refreshToken = Cookie.get(refreshTokenCookieName);

      if (error.response.status === tokenHTTPCodeError) {
        if (!refreshTokenUri || originalRequest.url.indexOf(refreshTokenUri) !== -1 || !refreshToken) {
          API.forgetAuth!();
          history.push(ERoutes.NotAuth);
        } else if (!originalRequest._retry) {
          originalRequest._retry = true;
          return API.post(refreshTokenUri, {
            [refreshTokenField]: refreshToken,
          })
            .then(({ data }) => onRefreshToken(data))
            .then(({ token, refreshToken }) => {
              API.setAuthToken!(token, tokenExpirationField);
              API.setRefreshToken!(refreshToken, tokenExpirationField);
            });
        }
      }
      return Promise.reject(error);
    }
  );
};

API.getTokenFromCookie = () => Cookie.get(tokenCookieName);

API.setRefreshToken = (token, tokenExpirationField) => Cookie.set(refreshTokenCookieName, token, { expires: getExpirationDate(token, tokenExpirationField) });

API.setAuthBasic = ((username, password) => {
  API.defaults.auth = { username, password };
}) as TAuthBasicCallback;

API.forgetAuthBasic = () => {
  delete API.defaults.auth;
};

API.setAuthToken = ((token, tokenExpirationField, tokenTimestampShift) => {
  Cookie.set(tokenCookieName, token, { expires: getExpirationDate(token, tokenExpirationField, tokenTimestampShift) });
  API.defaults.headers!.common['Authorization'] = `Bearer ${token}`;
}) as TAuthTokenCallback;

API.forgetAuthToken = () => {
  Cookie.delete(tokenCookieName);
  Cookie.delete(refreshTokenCookieName);
  delete API.defaults.headers!.common['Authorization'];
};

API.forgetAuth = () => {
  API.forgetAuthBasic!();
  API.forgetAuthToken!();
};

export default API;

import API from '..';

import {
  TCustomerSelfie,
  TCustomerAnswer,
  TCustomerCartValidation,
  TCustomerSendRecap,
  TCustomerAnswerResponse,
  TCustomerSelfieResponse,
  TCustomerCartValidationResponse,
  TCustomerSelfieBody,
  TCustomerAnswerBody,
  TGenerateECommerceResponse,
} from './interfaces/customer';

export const customerSelfie: TCustomerSelfie = (body: TCustomerSelfieBody, locale: String) =>
  API.post<TCustomerSelfieResponse>(`/customer-answer/selfie?locale=${locale}`, body).then(({ data }) => data);

export const customerAnswer: TCustomerAnswer = (body: TCustomerAnswerBody, locale: String) =>
  API.post<TCustomerAnswerResponse>(`/customer-answer?locale=${locale}`, body).then(({ data }) => data);

export const customerCartValidation: TCustomerCartValidation = async (id, body) =>
  API.post<TCustomerCartValidationResponse>(`/cart/${id}/customer-cart-validation`, body).then(({ data }) => data);

export const customerSendRecap: TCustomerSendRecap = (body) => API.post<TCustomerCartValidationResponse>(`/send-recap`, body).then(({ data }) => data);

export const generateECommerce: (eCommerceToken: string) => Promise<TGenerateECommerceResponse> = (eCommerceToken) =>
  API.post<TGenerateECommerceResponse>('/token/generate', { 'e-commerce_token': eCommerceToken }).then(({ data }) => data);

import { FunctionComponent, useContext } from 'react';

import { LocaleContext } from '@/providers/LocaleProvider';

import { TScoreCardProps } from './interface';
import Styles from './styles.module.scss';

const ScoreCard: FunctionComponent<TScoreCardProps> = ({ data, index, onGoToDetailedScore, setScoreIndexToDisplay }) => {
  const { front_name, name, score, score_type } = data;
  const { getTranslation } = useContext(LocaleContext);

  return (
    <div
      className={Styles.scoreCard__container}
      onClick={() => {
        setScoreIndexToDisplay(index);
        onGoToDetailedScore();
      }}
    >
      <div className={Styles.scoreCard__summaryContainer}>
        <p className={Styles.scoreCard__name}>{name}</p>
        <p>{score}/100</p>
        <p className={`${Styles.scoreCard__rating} ${Styles[getTranslation(`page.diagnosisStep.color.${score_type}`)]}`}>{front_name}</p>
      </div>
    </div>
  );
};

export default ScoreCard;

import React from 'react';

import useDidUpdate from '@/hooks/useDidUpdate';

import { FormContext } from '../provider';

import Styles from './styles.module.scss';

import validation from '@/helpers/validation';
import { EValidation, TValidation } from '@/helpers/validation/interfaces';

import { TFormOverrideCallback, TFormOverrideCallbacks } from '../interfaces';

import { TRadioButtonProps } from './interfaces';

const RadioButton: React.FC<TRadioButtonProps> = ({
  name,
  className = '',
  id,
  value,
  defaultChecked,
  checked,
  onChange,
  onChanged,
  required,
  validate,
  children,
}) => {
  const formContext = React.useContext(FormContext);
  const itemId = React.useId();

  const [_value, setValue] = React.useState(defaultChecked ?? checked ?? (formContext.datas[name] as string | undefined) === value ?? false);
  const [error, setError] = React.useState<string | null>(null);

  const _onChange = React.useCallback(
    (__value: boolean) => {
      const changeValue = onChange?.(__value, value);

      setValue(changeValue === undefined ? __value : changeValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [onChange, value]
  );

  useDidUpdate(() => {
    _onChange(checked ?? false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  React.useEffect(() => {
    formContext.datas[name] = _value ? value : formContext.datas[name] === value ? '' : formContext.datas[name];
    if (_value && typeof formContext.override[name] === 'object') {
      for (const _itemId in formContext.override[name] as TFormOverrideCallbacks) {
        if (_itemId !== itemId) {
          (formContext.override[name] as TFormOverrideCallbacks)[_itemId](value);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, _value]);

  React.useEffect(
    () => () => {
      delete formContext.datas[name];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name]
  );

  React.useEffect(() => {
    if (!formContext.override[name]) {
      formContext.override[name] = {};
    }

    (formContext.override[name] as TFormOverrideCallbacks)[itemId] = ((data) => _onChange(data === value)) as TFormOverrideCallback;
    return () => {
      delete (formContext.override[name] as TFormOverrideCallbacks)[itemId];
      if (Object.keys(formContext.override[name]).length === 0) {
        delete formContext.override[name];
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_onChange, name]);

  React.useEffect(() => {
    const _validate: TValidation = JSON.parse(JSON.stringify(validate ?? {}));

    if (required) {
      _validate[EValidation.Required] = {
        enabled: true,
        message: _validate[EValidation.Required]?.message ?? 'this field is required !',
      };
    }

    const isNotEmpty = Object.keys(_validate).length > 0;

    if (isNotEmpty) {
      formContext.validate[name] = (__value) => {
        const _error = validation(_validate, __value);

        setError(_error);
        return _error === null;
      };
    }

    return () => {
      if (isNotEmpty) {
        delete formContext.validate[name];
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, required, validate]);

  useDidUpdate(() => {
    onChanged?.(_value, value);
  }, [_value]);

  return (
    <div className={`${Styles['radioButton']} ${className ? className : ''}`}>
      <label className={Styles['radioButton__button']}>
        <input
          id={id}
          className={Styles['radioButton__input']}
          name={name}
          type='radio'
          value={value.toString()}
          required={required}
          checked={_value}
          onChange={({ target }) => _onChange(target.checked)}
        />
        <div className={Styles['radioButton__label']}>{children}</div>
      </label>
      {error && <p>{error}</p>}
    </div>
  );
};

export default RadioButton;

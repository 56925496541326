import { FunctionComponent, createContext, useCallback, useContext, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import MultiStep, { TMultiStepRef } from '@/components/MultiStep';
import PopinError from '@/components/PopinError';

import closeIcon from '@/globals/images/closeIcon.svg';

import { customerAnswer, customerCartValidation, customerSelfie } from '@/helpers/API/requests/customer';
import generateCartUrl from '@/helpers/generateCartUrl';

import { ECommerceContext } from '@/providers/AuthProvider/eCommerceInfoProvider';
import { LocaleContext } from '@/providers/LocaleProvider';

import CartStep from './CartStep';
import DiagnosisStep from './DiagnosisStep';
import HomeStep from './HomeStep';
import PersonalizationStep from './PersonalizationStep';
import PicturePreparationStep from './PicturePreparationStep';
import PictureStep from './PictureStep';
import RecommendationStep from './RecommendationStep';

import Styles from './styles.module.scss';
import { TCustomerAnswerResponse, TCustomerSelfieResponse } from '@/helpers/API/requests/interfaces/customer';
import { TProductInCart } from './RecommendationStep/interfaces';
import ScoreDetailStep from './ScoreDetailStep';

type TProcessContext = {
  picture: string;
  diagnosis: TCustomerSelfieResponse | null;
  recommendations: TCustomerAnswerResponse | null;
  goToHome: () => void;
  sendPicture: (image: string) => void;
};

export const ProcessContext = createContext<TProcessContext>({
  picture: '',
  diagnosis: null,
  recommendations: null,
  goToHome: () => {},
  sendPicture: () => {},
});

type LocationState = {
  state: {
    step: number;
  };
};

const ProcessAI: FunctionComponent = () => {
  const cart = useRef<TProductInCart[] | null>(null);
  const multiStep = useRef<TMultiStepRef>(null);

  const [cartId, setCartId] = useState<any>(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [diagnosis, setDiagnosis] = useState<TCustomerSelfieResponse | null>(null);
  const [errorHautAiPopin, setErrorHautAiPopin] = useState<boolean>(false);
  const [isTransitionPrev, setIsTransitionPrev] = useState<boolean>(false);
  const [picture, setPicture] = useState('');
  const [recommendations, setRecommendations] = useState<TCustomerAnswerResponse | null>(null);
  const [stepTransition, setStepTransition] = useState<boolean>(false);
  const [scoreIndexToDisplay, setScoreIndexToDisplay] = useState(0);

  const location = useLocation() as LocationState;

  const localeContext = useContext(LocaleContext);
  const { externalCartUrl, type } = useContext(ECommerceContext);

  const goToHome = useCallback(() => {
    setErrorHautAiPopin(false);
    multiStep.current!.goToStep(1);
  }, []);

  const sendPicture = (image: string) => {
    const pictureObject = {
      base64: image,
    };

    setPicture(image);
    customerSelfie(pictureObject, localeContext.locale)
      .then(setDiagnosis)
      .catch((err) => {
        console.error(err);
        setErrorHautAiPopin(true);
      });
  };

  const closeWidget = () => {
    const mainContainer = document.getElementById('sa-diagnosis') as HTMLElement;
    mainContainer.style.display = 'none';
    goToHome();
  };

  const sendQuestionnaire = (answers: any, _diagnosis: any) => {
    setCustomerEmail(answers.customer_email);
    answers.scores = _diagnosis.results.reduce((res: any, { slug, score }: any) => {
      return {
        ...res,
        [slug]: score,
      };
    }, {});

    customerAnswer(answers, localeContext.locale).then((response) => {
      setRecommendations(response);
      setCartId(response.cart_id);
    });
  };

  const sendCartValidation = async (products: number[]) => {
    const cart = {
      checked_products: products,
    };
    await customerCartValidation(cartId, cart);
  };

  const redirectToECommerceCart = () => {
    if (cart.current === null) return;
    const externalProductIds = cart.current.map((product) => product.externalId);
    const cartUrl = generateCartUrl({ externalCartUrl, type, externalProductIds, customerEmail, cart_id: cartId });
    window.location.href = cartUrl;
  };

  const submitCart = async () => {
    if (cart.current === null) return;
    const productIds = cart.current.map((product) => product.id);
    await sendCartValidation(productIds);
    redirectToECommerceCart();
  };

  return (
    <ProcessContext.Provider value={{ picture, diagnosis, recommendations, goToHome, sendPicture }}>
      <article className={Styles.mainContainer}>
        <div className={`${Styles.processIA} ${stepTransition ? Styles['is-transition'] : ''} ${isTransitionPrev ? Styles['is-prev'] : ''}`}>
          {multiStep.current?.currentStep.name !== 'score-detail' && (
            <div className={Styles.closeIconContainer} onClick={closeWidget}>
              <img className={Styles.closeIcon} src={closeIcon} alt='close button' />
            </div>
          )}
          <MultiStep
            initialStep={location.state?.step || undefined}
            ref={multiStep}
            delay={300}
            onPrevStep={() => {
              setIsTransitionPrev(true);
            }}
            onTransitionStart={() => {
              setTimeout(() => {
                setStepTransition(true);
              }, 50);
            }}
            onTransitionEnded={() => {
              setIsTransitionPrev(false);
              setStepTransition(false);
            }}
          >
            <MultiStep.Step name='home'>
              <div className={Styles['processIA__step']}>
                <HomeStep
                  onSubmit={() => {
                    setDiagnosis(null);
                    setRecommendations(null);
                    multiStep.current!.next();
                  }}
                />
              </div>
            </MultiStep.Step>

            <MultiStep.Step name='prepareForPicture'>
              <div className={Styles['processIA__step']}>
                <PicturePreparationStep
                  onSubmit={() => {
                    multiStep.current!.next();
                  }}
                />
              </div>
            </MultiStep.Step>

            <MultiStep.Step name='takePicture'>
              <div className={Styles['processIA__step']}>
                <PictureStep
                  onSubmit={() => {
                    multiStep.current!.next();
                  }}
                  onError={goToHome}
                />
              </div>
            </MultiStep.Step>

            <MultiStep.Step name='personalization'>
              <div className={Styles['processIA__step']}>
                <PersonalizationStep
                  onSubmit={(answers, _diagnosis) => {
                    sendQuestionnaire(answers, _diagnosis);
                    multiStep.current!.next();
                  }}
                />
              </div>
            </MultiStep.Step>

            <MultiStep.Step name='diagnosis'>
              <div className={Styles['processIA__step']}>
                <DiagnosisStep
                  onGoToDetailedScore={() => multiStep.current!.goToStep('score-detail')}
                  onSubmit={() => {
                    multiStep.current!.next();
                  }}
                  setScoreIndexToDisplay={setScoreIndexToDisplay}
                />
              </div>
            </MultiStep.Step>

            <MultiStep.Step name='recommendation'>
              <div className={Styles['processIA__step']}>
                <RecommendationStep
                  products={cart.current}
                  prev={multiStep.current?.prev}
                  onSubmit={(products) => {
                    cart.current = products;
                    multiStep.current!.next();
                  }}
                />
              </div>
            </MultiStep.Step>

            <MultiStep.Step name='cart'>
              <div className={Styles['processIA__step']}>
                <CartStep
                  products={cart.current!}
                  prev={multiStep.current?.prev}
                  onSubmit={() => {
                    submitCart();
                  }}
                />
              </div>
            </MultiStep.Step>

            <MultiStep.Step name='score-detail'>
              <div className={Styles['processIA__step']}>
                <ScoreDetailStep data={diagnosis?.results[scoreIndexToDisplay]} onGoBack={() => multiStep.current?.goToStep('diagnosis')} picture={picture} />
              </div>
            </MultiStep.Step>
          </MultiStep>

          <PopinError
            active={errorHautAiPopin}
            onClose={goToHome}
            title={'global.popinErrorHautAi.title'}
            description={'global.popinErrorHautAi.description'}
            backHome={'global.popinErrorHautAi.backHome'}
          />
        </div>
      </article>
    </ProcessContext.Provider>
  );
};

export default ProcessAI;

import { FunctionComponent } from 'react';

import LocaleText from '@/components/Intl/LocaleText';

import closeIcon from '@/globals/images/closeIcon.svg';

import Styles from './styles.module.scss';

const NotAuth: FunctionComponent = () => {
  const closeWidget = () => {
    const topContainer = document.getElementById('sa-diagnosis') as HTMLElement;
    topContainer.style.display = 'none';
  };

  return (
    <article className={Styles.mainContainer}>
      <div className={Styles.notAuthContainer}>
        <div className={Styles.closeIconContainer} onClick={closeWidget}>
          <img className={Styles.closeIcon} src={closeIcon} alt='close button' />
        </div>
        <LocaleText>unauthorizedAccess</LocaleText>
      </div>
    </article>
  );
};

export default NotAuth;

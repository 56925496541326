import { FunctionComponent } from 'react';

import ScoreDetail from '@/components/ScoreDetail';

import { TScoreDetailStep } from './interface';
import Styles from './styles.module.scss';

const ScoreDetailStep: FunctionComponent<TScoreDetailStep> = ({ data, onGoBack, picture }) => {
  if (data === undefined) return null;

  return (
    <div className={Styles.scoreDetailStep}>
      <ScoreDetail data={data} onGoBack={onGoBack} picture={picture} />
    </div>
  );
};

export default ScoreDetailStep;

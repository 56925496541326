import React from 'react';

import {
  TUser,
  IAuthProps,
  EAuthActionType,
  EAuthType,
  TAuthTypeBasicValues,
  TAuthTypeBTokenValues,
  TAuthRetrieveUserCallback,
  TAuthProps,
} from './interfaces';
import reducer, { defaultState } from './reducer';

import API from '@/helpers/API';
import { ECommerceContext } from './eCommerceInfoProvider';

export const AuthContext = React.createContext<IAuthProps>({
  user: null,

  login: (values, user, options) => {},

  logout: () => {},

  editUser: (user: TUser) => {},
});

AuthContext.displayName = 'Auth';

export const AuthConsumer = AuthContext.Consumer;

/* TODO Check how this can be refactored since there is only the URL token option for authentication
 * The login / logout functions, refresh token options, etc. can probably be simplified */
const AuthProvider: React.FC<TAuthProps> = ({
  onRetrieveUserFromCookie,
  tokenExpirationField,
  tokenTimestampShift = 1000,
  renderLoading = null,
  refreshTokenOptions,
  children,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [state, dispatch] = React.useReducer(reducer, defaultState);
  const { token: eCommerceToken, eCommerceLoading } = React.useContext(ECommerceContext);

  /**
   * Config Authentification context's callbacks
   */
  const authContext = React.useMemo<IAuthProps>(
    () => ({
      ...state,

      login: (values, user, { type = EAuthType.BToken, refreshToken } = {}) => {
        switch (type) {
          case EAuthType.Basic:
            const { username, password } = values as TAuthTypeBasicValues;

            API.setAuthBasic!(username, password);
            break;
          case EAuthType.BToken:
            API.setAuthToken!(values as TAuthTypeBTokenValues, tokenExpirationField, tokenTimestampShift);
            if (refreshToken) {
              API.setRefreshToken!(refreshToken, tokenExpirationField);
            }
            break;
        }
        dispatch({ type: EAuthActionType.Set, user });
      },

      logout: () => {
        API.forgetAuthToken!();
        dispatch({ type: EAuthActionType.Set, user: null });
      },

      editUser: (user: TUser) => {
        dispatch({ type: EAuthActionType.Edit, user });
      },
    }),
    [state, tokenExpirationField, tokenTimestampShift]
  );

  /**
   * Check if user exists before loading page.
   */
  React.useEffect(() => {
    if (refreshTokenOptions) {
      if (!refreshTokenOptions.tokenExpirationField) {
        refreshTokenOptions.tokenExpirationField = tokenExpirationField;
      }

      API.initRefreshToken!(refreshTokenOptions);
    }

    if (eCommerceToken) {
      API.setAuthToken!(eCommerceToken, tokenExpirationField);
      onRetrieveUserFromCookie(eCommerceToken)
        .then((user) => {
          dispatch({ type: EAuthActionType.Set, user });
        })
        .catch(() => {
          API.forgetAuth!();
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eCommerceToken]);

  /**
   * If token is valid, set loading to false once user is loaded.
   */
  React.useEffect(() => {
    if (loading === true && state.user !== null) {
      setLoading(false);
    }
  }, [loading, state.user]);

  return loading || eCommerceLoading ? renderLoading : <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
};

export { EAuthType };

export type { TAuthRetrieveUserCallback };

export default AuthProvider;

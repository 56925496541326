import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ERoutes from './routes';

/**
 * Screens
 */
import NotAuth from '@/screens/NotAuth';

import NotFound from '@/screens/NotFound';

const NotAuthRouter: React.FC = () => (
  <Routes>
    <Route path={ERoutes.NotAuth} element={<NotAuth />} />
    <Route path='*' element={<NotFound redirect={ERoutes.NotAuth} />} />
  </Routes>
);

export default NotAuthRouter;

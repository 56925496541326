import { FunctionComponent, useEffect, useState } from 'react';

import Slider, { Settings } from 'react-slick';

import Inner from '@/components/Inner';
import LocaleText from '@/components/Intl/LocaleText';
import RecommendationCard from '@/components/RecommandationCard';
import SubRoutine from '@/components/SubRoutine';

import { TRoutine } from './interfaces';
import Styles from './styles.module.scss';

const Routine: FunctionComponent<TRoutine> = ({
  index,
  rIndex,
  routine,
  isCurrentStep,
  active,
  recommendationRoutines,
  onStepChange,
  onCardSelect,
  onRoutineReset,
}) => {
  const [activeCard, setActiveCard] = useState(routine.proposed_products.findIndex(({ externalId }) => (active ? externalId === active.externalId : false)));
  const [showSubRoutines, setShowSubRoutines] = useState(false);

  const onProductSelect = (id: number, externalId: string, active: boolean, routineIndex: number, pIndex: number) => {
    if (active) {
      setActiveCard(pIndex);
    } else {
      setActiveCard(-1);
    }

    onCardSelect(id, externalId, active, routineIndex);
  };

  const sliderSettings: Settings = {
    arrows: false,
    infinite: false,
    variableWidth: true,
  };

  useEffect(() => {
    const indexFound = routine.proposed_products.findIndex(({ externalId }) => (active ? externalId === active.externalId : false));
    setActiveCard(indexFound);
  }, [active, routine.proposed_products]);

  return (
    <div key={'recommendationRoutine-' + rIndex}>
      <Inner
        className={Styles.recommendationRoutine__heading}
        type={'full'}
        onClick={() => {
          isCurrentStep ? onStepChange(-1) : onStepChange(rIndex);
        }}
      >
        <div className={Styles.recommendationRoutine__title}>
          <span className={Styles.recommendationRoutine__title__number}>{rIndex + 1}</span>
          {routine.title}
        </div>
      </Inner>
      <div className={Styles.recommendationRoutine__body}>
        <Inner type='full'>
          <Slider className={Styles.recommendationRoutine__slider} {...sliderSettings}>
            {routine?.proposed_products?.map((product: any, pIndex: number) => (
              <RecommendationCard
                key={'recommendationCard-' + pIndex}
                {...product}
                isActive={activeCard === pIndex}
                onSelect={(id, externalId, active, i) => onProductSelect(id, externalId, active, index, pIndex)}
              />
            ))}
          </Slider>
        </Inner>
      </div>
      <div className={Styles.recommendationRoutine__footer}>
        <Inner type='full'>
          {routine.sub_routines.length > 0 ? (
            <div
              className={Styles.recommendationRoutine__subMore}
              onClick={() => {
                setShowSubRoutines(!showSubRoutines);
              }}
            >
              <LocaleText>page.recommendationStep.subRoutines</LocaleText>
            </div>
          ) : (
            ''
          )}
        </Inner>
        <div className={`${Styles.recommendationRoutine__subs} ${showSubRoutines ? Styles['recommendationRoutine__subs--active'] : ''}`}>
          {routine.sub_routines
            .sort((a: any, b: any) => a.position - b.position)
            .map((sub_routine: any, rIndex: number) => (
              <SubRoutine
                key={'recommendationRoutine-' + index + '-sub-' + rIndex}
                index={index + rIndex + 1}
                rIndex={rIndex}
                parentIndex={index}
                routine={sub_routine}
                active={recommendationRoutines?.[index + rIndex + 1]}
                isCurrentStep={isCurrentStep}
                onStepChange={onStepChange}
                onCardSelect={onCardSelect}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Routine;

import React from 'react';

import Form from '@/components/Form';
import LocaleText from '@/components/Intl/LocaleText';

import BannerImage from '@/globals/images/hero_pathologyStep.png';

import { ECustomerPathology } from '@/helpers/API/requests/interfaces/customer';

import { ECommerceContext } from '@/providers/AuthProvider/eCommerceInfoProvider';

import { TPersonalizationStepForm } from './interfaces';
import Layout from './Layout';

const Pathology: React.FC<TPersonalizationStepForm> = ({ formRef }) => {
  const { availablePathologies } = React.useContext(ECommerceContext);
  return (
    <Layout title='form.personalizationStep.pathology.title' image={BannerImage}>
      {Object.values(ECustomerPathology).map((value) => {
        if (value !== 'none' && !availablePathologies.includes(value)) {
          return null;
        }
        return (
          <Form.RadioButton
            key={value}
            name='pathology'
            value={value}
            onChanged={() => {
              formRef.current!.next();
            }}
          >
            <LocaleText>{'form.personalizationStep.radio.' + value}</LocaleText>
          </Form.RadioButton>
        );
      })}
    </Layout>
  );
};

export default Pathology;
